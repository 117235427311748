import * as React from "react"
// import PropTypes from "prop-types"

// import { graphql, useStaticQuery } from 'gatsby'
// import { MDXRenderer } from 'gatsby-plugin-mdx'
import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import ProductTile from "./productTile"
import Title from "./title"

const ProductTilesSection = ( {data, location, subcategoryName} ) => {

    return (
      <Container>
        {subcategoryName &&
          <AnimationWrapper className="subcategory-name-wrapper" animationType="fadeInUp">
            <Title className="subcategory-name" title={subcategoryName} />
          </AnimationWrapper>
        }
        <div className={`${location}-tiles product-tiles-section`}>
          {data.nodes.map((node) => (
            <ProductTile product={node} location={location} />
          ))}
        </div>
      </Container>
    )
  }

export default ProductTilesSection