import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

// import LightboxImage from "./lightboxImage"
import Container from "../components/container"
import "./iconSection.css"

const IconSection = ( { data, location, image } ) => {

    return (

      <div className={`${location}-icons icons-section`}>

        <Container className="icons-grid-container">
            {image &&
              <div className="image-container">
                {/* <LightboxImage image={image} /> */}
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                />
              </div>
            }
            <div className={image ? 'icons-grid' : 'icons-grid no-image'}>
              {data.icons.map((icon) => (
                <div className="icon-wrapper">

                  <GatsbyImage
                    image={icon.image.childImageSharp.gatsbyImageData}
                    alt={icon.title}
                  />

                  <div className="icon-txt-wrapper">
                    <h4 className="icon-title">
                      {icon.title}
                    </h4>
                    <p className="icon-desc">
                      {icon.text}
                    </p>
                  </div>

                </div>
              ))}
            </div>
        </Container>
      </div>

    )
  }

export default IconSection